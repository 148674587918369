import React from 'react'
import * as FaIcons from 'react-icons/fa'
import * as AiIcons from 'react-icons/ai'
import './UploadImage.css'

const UploadImage = (props) => {
  return (
    <div className='border-dashed border-brand'>
      <input
        type='file'
        id='upload-input-image-file'
        hidden='true'
        accept='image/*'
        // capture='camera'
        onChange={props.onChange}
      />
      <label for='upload-input-image-file'>
        <div className='upload-image-container'>
          <AiIcons.AiOutlinePlusCircle className='toggle-icon-plus text-brand' size={60} />
        </div>
      </label>
    </div>
  )
}

export default UploadImage
