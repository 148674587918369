import React, { useState, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import firebase from './firebase/firebaseConfig'

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [authenticated, setAuthenticated] = useState(false)
  const [loadingAuth, setLoadingAuth] = useState(true)

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setAuthenticated(true)
      } else {
        setAuthenticated(false)
      }
      setLoadingAuth(false)
    })
  }, [])

  return loadingAuth ? (
    'loading...'
  ) : (
    <Route
      {...rest}
      render={(props) => {
        if (authenticated) {
          return <Component {...props} />
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login/app',
                state: { from: props.location },
              }}
            />
          )
        }
      }}
    />
  )
}

export default ProtectedRoute
