import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'
import 'firebase/analytics'
import config from './config'

firebase.initializeApp(config)
firebase.analytics()
firebase.auth()

export default firebase
