/**
 * @name DateDate
 *
 * @synopsis
 * ```coffeescript [specscript]
 * DateDate(date Date) -> dayDate string // '23'
 * ```
 */
const DateDate = function (date) {
  return date.getDate()
}

export default DateDate
