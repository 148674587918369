const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

/**
 * @name DateMonth
 *
 * @synopsis
 * ```coffeescript [specscript]
 * DateMonth(date Date) -> month string // 'May'
 * ```
 */
const DateMonth = function (date) {
  return months[date.getMonth()]
}

export default DateMonth
