import React from 'react'
import buttonStyles from './Button.module.css'

function Button(props) {
  return (
    <>
      <button
        style={props.style}
        className={buttonStyles.button + ' ' + props.className}
        onClick={props.fx}
      >
        {props.title}
      </button>
    </>
  )
}

export default Button
