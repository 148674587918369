import React from 'react'
import { useHistory } from 'react-router-dom'
import * as FaIcons from 'react-icons/fa'
import './SideNavbar.css'

function SideNavbar(props) {
  const history = useHistory()

  return (
    <ul className='side-navbar'>
      <li
        onClick={() => history.push('/portal')}
        style={
          window.location.pathname === '/portal'
            ? { backgroundColor: '#ddd' }
            : null
        }
        className='tablinks'
      >
        <div className='side-navbar-icon'>
          <FaIcons.FaThLarge size={20} />
        </div>
        <div className='side-navbar-title'>Portal</div>
      </li>

      <li
        onClick={() => history.push('/app')}
        style={
          window.location.pathname === '/app'
            ? { backgroundColor: '#ddd' }
            : null
        }
        className='tablinks'
      >
        <div className='side-navbar-icon'>
          <FaIcons.FaCamera size={20} />
        </div>
        <div className='side-navbar-title'>Add Document</div>
      </li>
    </ul>
  )
}

export default SideNavbar
