import React from 'react'
import TextInputContainerStyles from './TextInputContainer.module.css'

function TextInputContainer(props) {
  return (
    <>
      <label id={props.labelId} className={TextInputContainerStyles.label + ' ' + props.labelClass}>
        {props.label}
      </label>
      <p className={TextInputContainerStyles.sublabel}>{props.sublabel}</p>
      <div
        style={{ width: props.width }}
        className={TextInputContainerStyles.inputContainer}
      >
        {props.children}
        <input
          className={TextInputContainerStyles.inputElement + ' ' + props.class}
          id={props.id}
          name={props.name}
          placeholder={props.placeholder}
          style={props.children ? { paddingLeft: '25px' } : null}
          value={props.value}
          onChange={props.onChange}
          required={props.required}
          maxLength={props.maxLength}
          disabled={props.disabled}
          // class='Field'
          pattern='^-?\d*[.,]?\d*$'
          type={props.type}
        />
      </div>
    </>
  )
}

export default React.memo(TextInputContainer)
