import React, { useState, useRef } from 'react'
import firebase from '../../firebase/firebaseConfig'
import truckDeskLogo from '../../assets/truckdesk_transparent.png'
import * as FaIcons from 'react-icons/fa'
import navbarStyles from './TopNavbar.module.css'
// import deleteAllCookies from '../../utils/deleteAllCookies'
import { Link, useHistory, useLocation } from 'react-router-dom'

function TopNavbar() {
  return (
    <>
      <Navbar>
        {/* <NavItem icon={<BellIcon />} /> */}

        {/* <button className={navbarStyles.upgradeButton}>Upgrade</button> */}

        <NavItem icon={<FaIcons.FaUser color='grey' size={20} />}>
          <DropdownMenu></DropdownMenu>
        </NavItem>
      </Navbar>
    </>
  )
}

function Navbar(props) {
  const history = useHistory()
  let location = window.location.pathname

  const navigateHome = () => {
    if (location.includes('app')) {
      history.push('/app')
    } else if (location.includes('portal')) {
      history.push('/portal')
    }
  }

  return (
    <nav className={navbarStyles.navbar}>
      <img
        style={{ cursor: 'pointer' }}
        onClick={navigateHome}
        alt='truckdesk logo'
        className={navbarStyles.truckdeskNavbarLogo}
        src={truckDeskLogo}
      />
      <ul className={navbarStyles.navbarNav}>{props.children}</ul>
    </nav>
  )
}

function NavItem(props) {
  const [open, setOpen] = useState(false)

  return (
    <li className={navbarStyles.navItem}>
      <a className={navbarStyles.iconButton} onClick={() => setOpen(!open)}>
        {props.icon}
      </a>

      {open && props.children}
    </li>
  )
}

function DropdownMenu() {
  const [activeMenu, setActiveMenu] = useState('main')
  const dropdownRef = useRef(null)

  const history = useHistory()

  function DropdownItem(props) {
    return (
      <a className={navbarStyles.menuItem} onClick={props.onClick}>
        <span style={{ marginLeft: '20px' }}>{props.children}</span>
      </a>
    )
  }

  const onLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      })
    history.push('/login/portal')
  }

  let location = window.location.pathname

  const navigateToContact = () => {

    history.push('/app/contact-us')

  }

  const navigateToProfile = () => {

    history.push('/app/profile')

  }

  const navigateToDocHistory = () => {

    history.push('/doc-history')

  }

  return (
    <div className={navbarStyles.dropdown} ref={dropdownRef}>
      <div className={navbarStyles.menu}>
        {/* <DropdownItem onClick={() => alert('coming soon')}>
          Settings
        </DropdownItem> */}
        <DropdownItem onClick={navigateToProfile}>Profile</DropdownItem>
        <DropdownItem onClick={navigateToDocHistory}>Document History</DropdownItem>
        <DropdownItem onClick={navigateToContact}>Contact Us</DropdownItem>
        <DropdownItem onClick={onLogout}>Logout</DropdownItem>
      </div>
    </div>
  )
}

export default TopNavbar
