import React, { useState, useEffect } from 'react'
import TextInputContainer from '../../components/TextInputContainer/TextInputContainer'
import Selected from '../../components/Selected/Selected'
import { EQUIPMENT_TYPE } from '../../constants/constants'
import firebase from '../../firebase/firebaseConfig'
import Navbar from '../../components/Navbar/Navbar'
import getFromLocalStorage from '../../utils/getFromLocalStorage'
import './ProfileApp.css'
import Button from '../../components/Buttons/Button'
import * as FaIcons from 'react-icons/fa'
import * as AiIcons from 'react-icons/ai'

const ProfileApp = () => {
  const [fullName, setfullName] = useState('')
  const [address, setaddress] = useState('')
  const [phoneNumber, setphoneNumber] = useState('')
  const [equipmentType, setequipmentType] = useState('')
  const [dispatchPhoneNumber, setdispatchPhoneNumber] = useState('')
  const [dispatcherEmail, setdispatcherEmail] = useState('')
  const [usdotNumber, setusdotNumber] = useState('')
  const [mcNumber, setmcNumber] = useState('')
  const [uploadCDL, setuploadCDL] = useState('')
  const [proofOfInsurance, setproofOfInsurance] = useState('')
  const [mcAuthorityDocument, setmcAuthorityDocument] = useState('')
  const [w9formfile, setw9formfile] = useState('')

  // BANK DETAILS
  const [accountName, setaccountName] = useState('')
  const [accountNumber, setaccountNumber] = useState('')
  const [routingNumber, setroutingNumber] = useState('')
  const [bankName, setbankName] = useState('')

  let emailValue = getFromLocalStorage('userEmail')

  useEffect(() => {
    firebase
      .firestore()
      .collection('users')
      .doc(emailValue)
      .onSnapshot(function (doc) {
        doc.data().fullName ? setfullName(doc.data().fullName) : setfullName('')
        doc.data().address ? setaddress(doc.data().address) : setaddress('')
        doc.data().phoneNumber
          ? setphoneNumber(doc.data().phoneNumber)
          : setphoneNumber('')
        doc.data().equipmentType
          ? setequipmentType(doc.data().equipmentType)
          : setequipmentType('')
        doc.data().equipmentType
          ? setdispatchPhoneNumber(doc.data().dispatchPhoneNumber)
          : setdispatchPhoneNumber('')
        doc.data().dispatcherEmail
          ? setdispatcherEmail(doc.data().dispatcherEmail)
          : setdispatcherEmail('')
        doc.data().usdotNumber
          ? setusdotNumber(doc.data().usdotNumber)
          : setusdotNumber('')
        doc.data().mcNumber ? setmcNumber(doc.data().mcNumber) : setmcNumber('')
        setuploadCDL(doc.data().uploadCDL)
        // setproofOfInsurance(doc.data().proofOfInsurance)
        doc.data().mcAuthorityDocument
          ? setmcAuthorityDocument(doc.data().mcAuthorityDocument)
          : setmcAuthorityDocument('')
        doc.data().w9formfile
          ? setw9formfile(doc.data().w9formfile)
          : setw9formfile('')
        // BANK DETAILS
        doc.data().accountName
          ? setaccountName(doc.data().accountName)
          : setaccountName('')
        doc.data().accountNumber
          ? setaccountNumber(doc.data().accountNumber)
          : setaccountNumber('')
        doc.data().routingNumber
          ? setroutingNumber(doc.data().routingNumber)
          : setroutingNumber('')
        doc.data().bankName ? setbankName(doc.data().bankName) : setbankName('')
      })
  }, [])

  const sendDataToFirestore = () => {
    firebase.firestore().collection('users').doc(emailValue).set(
      {
        fullName,
        address,
        phoneNumber,
        equipmentType,
        dispatchPhoneNumber,
        dispatcherEmail,
        usdotNumber,
        uploadCDL,
        proofOfInsurance,
        mcAuthorityDocument,
        mcNumber,
        w9formfile,
        accountName,
        accountNumber,
        routingNumber,
        bankName,
      },
      { merge: true }
    )
    alert('Profile successfully updated.')
  }

  const submit = () => {
    sendDataToFirestore()
  }

  return (
    <>
      <Navbar />
      <div className='profile-container' id='profile-app-container'>
          <div className='flex flex-row justify-between justify-center my-2'>
              <div className='flex flex-col justify-center'>&nbsp;</div>
              <div><Button style={{ width: '100%' }} className='bg-white rounded shadow text-brand py-1 px-6 font-bold' fx={submit} title='Save' /> </div>
          </div>
        <div className='bg-white px-5 py-4 rounded-xl mt-2 shadow'>
        <TextInputContainer
          id='profile-app-textinput'
          label='Full Name'
          value={fullName}
          class='w-full py-6 mt-0'
          onChange={(e) => setfullName(e.target.value)}
          placeholder='Full Name'
        />
        <TextInputContainer
          id='profile-app-textinput'
          label='Address'
          value={address}
          class='w-full py-6 mt-0'
          onChange={(e) => setaddress(e.target.value)}
          placeholder='Address (#, Street, State, Zip Code)'
        />
        <TextInputContainer
          id='profile-app-textinput'
          label='Phone Number'
          value={phoneNumber}
          class='w-full py-6 mt-0'
          onChange={(e) => setphoneNumber(e.target.value)}
          placeholder='Phone Number'
        />
        <Selected
          color='black'
          onChange={(e) => setequipmentType(e)}
          value={EQUIPMENT_TYPE.value}
          options={EQUIPMENT_TYPE}
          className='w-full equip_dd'
          label='Equipment Type'
        />
        <TextInputContainer
          id='profile-app-textinput'
          label='Dispatcher Phone Number'
          value={dispatchPhoneNumber}
          class='w-full py-6 mt-0'
          onChange={(e) => setdispatchPhoneNumber(e.target.value)}
          placeholder='Dispatcher Phone Number'
        />
        <TextInputContainer
          type={'email'}
          id='profile-app-textinput'
          label='Dispatcher Email'
          value={dispatcherEmail}
          class='w-full py-6 mt-0'
          onChange={(e) => setdispatcherEmail(e.target.value)}
          placeholder='Dispatcher Email'
        />
        <TextInputContainer
          id='profile-app-textinput'
          label='USDOT Number'
          value={usdotNumber}
          class='w-full py-6 mt-0'
          onChange={(e) => setusdotNumber(e.target.value)}
          placeholder='USDOT Number'
        />
        <TextInputContainer
          id='profile-app-textinput'
          label='MC Number'
          value={mcNumber}
          class='w-full py-6 mt-0'
          onChange={(e) => setmcNumber(e.target.value)}
          placeholder='MC Number'
        />
        <label for='upload-cdl'>Upload CDL</label>
        <div className='w-full h-12 bg-blue-100 file-upload relative'>
            <div className='file-upload-label flex flex-row px-2 py-4'><FaIcons.FaUpload color='#16a5de'/> <span
                className='ml-2 -mt-1 font-bold text-brand'>Choose File</span></div>
            <input
                type='file'
                id='upload-cdl'
                name='upload-cdl'
                className='opacity-0 absolute z-100 top-0 h-12 w-full'
                accept='image/*'
                onChange={(e) => {
                    console.log(e.target.files[0]);
                    setuploadCDL(e.target.files[0]);
                }}
            />
        </div>
        <br/>
        <label for='upload-proof-of-insurance'>Upload Proof on Insurance</label>
            <div className='w-full h-12 bg-blue-100 file-upload relative'>
                <div className='file-upload-label flex flex-row px-2 py-4'><FaIcons.FaUpload color='#16a5de'/> <span className='ml-2 -mt-1 font-bold text-brand'>Choose File</span></div>
                <input
                  type='file'
                  id='upload-proof-of-insurance'
                  name='upload-proof-of-insurance'
                  className='opacity-0 absolute z-100 top-0 h-12 w-full'
                  accept='image/*'
                  onChange={(e) => {
                    console.log(e.target.files[0])
                  }}
                />
            </div>
        <br />
        <label for='w9'>Upload Completed & Signed W-9</label>
        <div className='w-full h-12 bg-blue-100 file-upload relative'>
        <div className='file-upload-label flex flex-row px-2 py-4'><FaIcons.FaUpload color='#16a5de'/> <span className='ml-2 -mt-1 font-bold text-brand'>Choose File</span></div>
        <input
          type='file'
          id='w9'
          name='w9'
          className='opacity-0 absolute z-100 top-0 h-12 w-full'
          accept='image/*'
          onChange={(e) => {
            console.log(e.target.files[0])
          }}
        />
        </div>
        </div>

        <div className='text-gray-500 mt-4 font-bold'>Bank Details</div>
        <div className='bg-white px-5 py-4 rounded-xl mt-2 shadow'>
        <TextInputContainer
          id='account-name'
          label='Account Name'
          value={accountName}
          class='w-full py-6 mt-0'
          onChange={(e) => setaccountName(e.target.value)}
          placeholder='Account Name'
        />
        <TextInputContainer
          id='account-number'
          label='Account Number'
          value={accountNumber}
          class='w-full py-6 mt-0'
          onChange={(e) => setaccountNumber(e.target.value)}
          placeholder='Account Number'
        />
        <TextInputContainer
          id='account-number'
          label='Routing Number'
          value={routingNumber}
          class='w-full py-6 mt-0'
          onChange={(e) => setroutingNumber(e.target.value)}
          placeholder='Routing Number'
        />
        <TextInputContainer
          id='account-number'
          label='Bank'
          value={bankName}
          class='w-full py-6 mt-0'
          onChange={(e) => setbankName(e.target.value)}
          placeholder='Bank'
        />
        </div>
      </div>
    </>
  )
}

export default ProfileApp
