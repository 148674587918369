import React from 'react'
import './PageNotFound.css'

function PageNotFound() {
  return (
    <div>
      <h2 className='page-not-found-text'>Page Not Found</h2>
    </div>
  )
}

export default PageNotFound
