import React, { useState } from 'react'
import './ForgotPassword.css'
import TextInputContainer from '../../components/TextInputContainer/TextInputContainer'
import Button from '../../components/Buttons/Button'
import { Link } from 'react-router-dom'
import firebase from '../../firebase/firebaseConfig'

function ForgotPassword() {
  const [email, setEmail] = useState('')

  const sendPasswordResetEmail = (email) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        // Password reset email sent!
        // ..
        alert('Password reset email sent!')
      })
      .catch((error) => {
        var errorCode = error.code
        var errorMessage = error.message
        // ..
      })
  }

  return (
    <>
      <div className='forgot-password-container'>
        <h1 style={{ marginBottom: '2rem', color: '#fff' }}>
          Please enter your email to reset your password...
        </h1>
        <div>
          <TextInputContainer
            id={'forgot-password-email-input'}
            label='Email Address'
            placeholder='Email Address'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className='login-button'>
          <Button
            fx={() => sendPasswordResetEmail(email)}
            style={{ width: '100%' }}
            title='Submit'
          />
        </div>
        <p style={{ color: 'grey', marginTop: '1rem', textAlign: 'center' }}>
          <Link
            style={{
              textDecoration: 'none',
              color: 'grey',
            }}
            to='/register/portal'
          >
            Don't have an account? Sign up here
          </Link>
        </p>
      </div>
    </>
  )
}

export default ForgotPassword
