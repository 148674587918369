import React, { useState } from 'react'
import './ContactUs.css'
import Navbar from '../../components/Navbar/Navbar'
import Button from '../../components/Buttons/Button'
import getFromLocalStorage from '../../utils/getFromLocalStorage'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

const ContactUs = () => {
  const [message, setMessage] = useState('')
  let userEmail = getFromLocalStorage('userEmail')
  const history = useHistory()
  let location = window.location.pathname

  const sendMessage = () => {
    if (!message) {
      alert('Please add a message.')
    }
    axios({
      method: 'POST',
      url:
        'https://us-central1-truckdesk-prototype.cloudfunctions.net/contactUsMessage',
      data: {
        message: message,
        senderEmail: userEmail,
      },
    })
      .then(function (response) {
        alert(`Your message has been sent successfully to Truckdesk!`)
        navigateHome()
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const navigateHome = () => {
    if (location.includes('app')) {
      history.push('/app')
    } else if (location.includes('portal')) {
      history.push('/portal')
    }
  }

  return (
    <>
      <Navbar />
      <div className='contact-us-container'>
        <div className='flex flex-row justify-between justify-center mt-2 mb-0'>
          <div className='flex flex-col justify-center'>&nbsp;</div>
          <div><Button style={{ width: '100%' }} className='bg-white rounded shadow text-brand py-1 px-6 font-bold' fx={sendMessage} title='Send' /> </div>
        </div>
        <div className='text-white font-bold text-xl mb-4'>Contact Us</div>
        <p className='text-white text-sm'>Got a question? Feel free to send us a message anytime.</p>
        <div className='bg-white px-5 py-4 rounded-xl mt-2 shadow'>
        <textarea
          className='contact-us-textarea border-2 p-2'
          style={{ width: '100%' }}
          placeholder='Message'
          id='message'
          name='message'
          rows='5'
          cols='33'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        </div>
      </div>
    </>
  )
}

export default ContactUs
