import React, { useState, useEffect, useRef } from 'react'
import './DocHistory.css'
import Navbar from '../../components/Navbar/Navbar'
import Button from '../../components/Buttons/Button'
import firebase from '../../firebase/firebaseConfig'
import getFromLocalStorage from '../../utils/getFromLocalStorage'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroll-component';
import RelativeTime from '../../utils/relativeTime'


const DocHistory = () => {
  const [docData, setDocData]  = useState([])
  const [currentlimit, setCurrentLimit] = useState(100)
  let loggedEmail = getFromLocalStorage('userEmail')
  const history = useHistory()
  let location = window.location.pathname

  useEffect(() => {
    if(loggedEmail) {
      return firebase
          .firestore()
          .collectionGroup('documents')
          .where("userEmail", "==", loggedEmail)
          .limit(100)
          .onSnapshot((snapshot) => {
            const data = []
            snapshot.forEach((doc) => data.push({...doc.data(), id: doc.id}))
            data.sort(function (a, b) {
              return b.timestamp - a.timestamp
            })
            setDocData(data)
          })
    }
  }, [])

  const fetchData = () => {
    let datalimit = currentlimit + 100
    setCurrentLimit(datalimit)
    if(loggedEmail) {
      return firebase
          .firestore()
          .collectionGroup('documents')
          .where("userEmail", "==", loggedEmail)
          .limit(datalimit)
          .onSnapshot((snapshot) => {
            const data = []
            snapshot.forEach((doc) => data.push({...doc.data(), id: doc.id}))
            data.sort(function (a, b) {
              return b.timestamp - a.timestamp
            })
            setDocData(data)
          })
    }

  }

  const refresh = () => {

  }

  const navigateHome = () => {
    history.push('/app')
  }

  return (
    <>
      <Navbar />
      <div className='contact-us-container'>
        <div className='flex flex-row justify-between justify-center mt-2 mb-0'>
          <div className='flex flex-col justify-center'>&nbsp;</div>
          <div> </div>
        </div>
        <div className='text-white font-bold text-xl mb-4'>Document History</div>
        <div className='bg-white px-5 py-4 rounded-xl mt-2 shadow bg-gray-100'>
          <InfiniteScroll
              dataLength={docData.length} //This is important field to render the next data
              next={fetchData}
              className='flex flex-col'
              hasMore={true}
              loader={<div className='font-bold text-center w-full'>Loading...</div>}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
              // below props only if you need pull down functionality
              refreshFunction={refresh}
              pullDownToRefresh
              pullDownToRefreshThreshold={50}
              pullDownToRefreshContent={
                <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
              }
              releaseToRefreshContent={
                <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
              }
          >
            {docData
                .filter((data) => {
                    return true
                })
                .map((data) => (
                    <div className='flex flex-col w-full bg-white rounded m-1 p-2'>
                        <div>{data.documentType}</div>
                        <div className='text-gray-500 text-xs'>Uploaded on {`${RelativeTime(data.timestamp)}`}</div>
                    </div>
                ))}
          </InfiniteScroll>
        </div>
      </div>
    </>
  )
}

export default DocHistory
