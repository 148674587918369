import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import * as FaIcons from 'react-icons/fa'
import DocButton from '../../components/Buttons/DocButton'
import Navbar from '../../components/Navbar/Navbar'
import addLocalStorage from '../../utils/addLocalStorage'
import TextInputContainer from '../../components/TextInputContainer/TextInputContainer'
import getFromLocalStorage from '../../utils/getFromLocalStorage'
import Modal from 'react-modal'
import './DriverApp.css'
import { withAlert } from 'react-alert'

const DriverAppDoc = ({alert}) => {
  const [selected, setSelected] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [loadNumber, setloadNumber] = useState('')
  const [bolNumber, setbolNumber] = useState('')
  const [isModalOpen, setisModalOpen] = useState(false)
  const history = useHistory()

  useEffect(() => {
    let docType = getFromLocalStorage('documentType')
    setSelected(docType)

    let modalSeen = getFromLocalStorage('modalSeen')
    if (modalSeen) {
      setisModalOpen(false)
    } else setisModalOpen(true)

    let email = getFromLocalStorage('contactEmail')
    if (email) {
      setContactEmail(email)
    }
  }, [])

  const onSelectHandler = (e) => {
    setSelected(e.target.value)
  }

  const closeModal = () => {
    setisModalOpen(false)
    addLocalStorage('modalSeen', true)
  }

  const navigate = () => {
    if (!contactEmail) {
      alert.error('Please add a contact email')
      return
    }
    if (!selected) {
      alert.error('Please select a document type')
      return
    } else {
      addLocalStorage('documentType', selected)
      addLocalStorage('contactEmail', contactEmail)
      addLocalStorage('loadNumber', loadNumber)
      addLocalStorage('bolNumber', bolNumber)
      history.push('/app/upload')
    }
  }

  const outerDivStyle = { width: '100%', display: 'inline-block' }
  const innerDivStyle = { display: 'flex', justifyContent: 'space-between' }

  const customStyles = {
    content: {
      width: '80%',
      height: '20%',
      top: '20%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }

  return (
    <>
      <Navbar />
      <Modal
        isOpen={isModalOpen}
        onAfterOpen={() => console.log('open modal')}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <button style={{ float: 'right' }} onClick={closeModal}>
          close
        </button>
        <p>Welcome to TruckDesk.</p>
      </Modal>
      <body style={{ backgroundColor: '#ddd', height: '100vh' }}>
      <div className='driver-app-document-container'>
          <div className='flex flex-row justify-between justify-center my-2'>
            <div className='flex flex-col justify-center'>&nbsp;</div>
            <div><button className='bg-white rounded shadow text-brand py-1 px-6 font-bold' onClick={navigate}>Next</button></div>
          </div>
          <div className='text-white font-xs mb-2'>Step 1 : Send to</div>
          <div></div>
          <div className='bg-white p-4 rounded-xl shadow'>
          <TextInputContainer
            id={'company-name-input'}
            /*label='Sending to' */
            placeholder='example@truckdesk.com'
            class='w-full py-6'
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
          />
          </div>
        <div className='text-gray-500 mt-4 font-bold'>Step 2: Load Details</div>
        <div className='bg-white p-4 rounded-xl mt-2 shadow'>
          <TextInputContainer
            id={'company-name-input'}
            label='Load Number'
            placeholder='Enter Load Number'
            class='w-full py-6 mt-0.5'
            labelClass='font-xs'
            required={false}
            maxLength={12}
            value={loadNumber}
            onChange={(e) => setloadNumber(e.target.value)}
          />
          <TextInputContainer
            id={'company-name-input'}
            label='BOL Number'
            placeholder='Enter BOL Number here'
            class='w-full py-6 mt-0.5'
            labelClass='font-xs'
            required={false}
            maxLength={12}
            value={bolNumber}
            onChange={(e) => setbolNumber(e.target.value)}
          />
        </div>
          <div className='text-gray-500 mt-4 font-bold'>Step 3: Select Document Type</div>
          <div className='bg-white px-2 py-2 rounded-xl mt-2 shadow'>
            <div className='flex flex-wrap'>
              <DocButton
                onClick={() => setSelected('Bill of Lading')}
                selected={selected === 'Bill of Lading' ? true : false}
                title='Bill of Lading'
              />
              <DocButton
                onClick={() => setSelected('Fuel Receipt')}
                selected={selected === 'Fuel Receipt' ? true : false}
                title='Fuel Receipt'
              />
              <DocButton
                  onClick={() => setSelected('Invoice')}
                  selected={selected === 'Invoice' ? true : false}
                  title='Invoice'
              />
              <DocButton
                onClick={() => setSelected('Proof Of Delivery')}
                selected={selected === 'Proof Of Delivery' ? true : false}
                title='Proof Of Delivery'
              />
              <DocButton
                  onClick={() => setSelected('Freight Bill')}
                  selected={selected === 'Freight Bill' ? true : false}
                  title='Freight Bill'
              />
              <DocButton
                  onClick={() => setSelected('Photo')}
                  selected={selected === 'Photo' ? true : false}
                  title='Photo'
              />
              <DocButton
                onClick={() => setSelected('Scale Receipt')}
                selected={selected === 'Scale Receipt' ? true : false}
                title='Scale Receipt'
              />
              <DocButton
                onClick={() => setSelected('Lumper Receipt')}
                selected={selected === 'Lumper Receipt' ? true : false}
                title='Lumper Receipt'
              />
              <DocButton
                onClick={() => setSelected('Other Documents')}
                selected={selected === 'Other Documents' ? true : false}
                title='Other Docs'
              />

            </div>
          </div>
          <div>
            <div className='driver-app-container-add-image'>
              <div className='flex flex-row justify-center pt-6'><a className='bg-brand text-white rounded p-2' href='https://calculator.truckdesk.com/'>Checkout Trucking Calculator</a></div>
            </div>
          </div>
        </div>

      </body>
    </>
  )
}

export default withAlert()(DriverAppDoc)
