import React, { useState } from 'react'
import firebase from '../../firebase/firebaseConfig'
import TextInputContainer from '../../components/TextInputContainer/TextInputContainer'
import truckDeskLogo from '../../assets/truckdesk_transparent.png'
import Button from '../../components/Buttons/Button'
import addLocalStorage from '../../utils/addLocalStorage'
import { Link, useHistory } from 'react-router-dom'
import './Login.css'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, seterror] = useState('')

  const history = useHistory()
  let location = window.location.pathname

  const loginWithEmail = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        addLocalStorage('userEmail', email)

        firebase.firestore().collection('users').doc(email).set(
          {
            lastLogin: new Date(),
          },
          { merge: true }
        )

        if (location.includes('app')) {
          history.push('/app')
        } else if (location.includes('portal')) {
          history.push('/portal')
        }
        // ...
      })
      .catch((error) => {
        var errorCode = error.code
        var errorMessage = error.message

        console.log(errorMessage)
        seterror(errorMessage)
      })
  }

  return (
    <>
      <body
        style={
          location.includes('app')
            ? { height: '100vh' }
            : null
        }
      >
        <div className='app-login-container'>
          <div>
            <img
              alt='truckdesk logo'
              className='truckdesk-logo'
              src={truckDeskLogo}
            />
            <div className='font-extrabold text-2xl mb-2'>Login</div>
            <TextInputContainer
              id={'register-input-email'}
              label='Email'
              class='w-full py-6'
              placeholder='Enter email address here'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextInputContainer
              id={'register-input-password'}
              label='Password'
              class='w-full py-6'
              placeholder='Enter your password here'
              value={password}
              type={'password'}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className='text-brand font-bold w-full -mt-1 mb-4 flex justify-end'>Forgot Password?</div>
          </div>
          <div className='register-button'>
            <Button
              style={{ width: '100%' }}
              title='Sign In'
              className='bg-brand mb-1 py-2 mt-2'
              fx={loginWithEmail}
            />
          </div>
          <p style={{ color: 'red', marginTop: '1rem' }}>
            {error ? error : null}
          </p>
          <p style={{ color: 'grey', textAlign: 'center' }}>
            {location.includes('app') ? (
              <>
                <Link
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                    fontWeight: 600,
                  }}
                  to='/register/app'
                >
                  Don't have an account yet? <span className='text-brand'>Sign up here</span>
                </Link>
                <Link
                  style={{
                    textDecoration: 'none',
                    color: 'grey',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    display: 'block',
                  }}
                  to='/login/portal'
                >
                    For Portal Login <span className='text-red-500'>Click Here</span>
                </Link>
              </>
            ) : (
              <>
                <Link
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                    fontWeight: 600,
                  }}
                  to='/register/portal'
                >
                  Don't have a portal account yet? <span className='text-brand'>Sign up here</span>
                </Link>
                <Link
                  style={{
                    textDecoration: 'none',
                    color: 'grey',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    display: 'block',
                  }}
                  to='/login/app'
                >
                  For App Login <span className='text-red-500 font-bold'>Click Here</span>
                </Link>
              </>
            )}
          </p>
        </div>
      </body>
    </>
  )
}

export default Login
