export const DEFAULT_PAYMENT_TERMS = [
  { value: 'Due upon receipt', label: 'Due upon receipt' },
  { value: 'Net 15', label: 'Net 15' },
  { value: 'Net 30', label: 'Net 30' },
  { value: 'Net 60', label: 'Net 60' },
  { value: 'Net 90', label: 'Net 90' },
]

export const DEFAULT_PAYMENT_MODE = [
  { value: 'Wire transfer', label: 'Wire transfer' },
  { value: 'Check', label: 'Check' },
  {
    value: 'Either Wire Transfer or Check',
    label: 'Either Wire Transfer or Check',
  },
]

export const DEFAULT_CURRENCY = [
  { value: 'USD', label: 'USD' },
  { value: 'CAD', label: 'CAD' },
]

export const EQUIPMENT_TYPE = [
  { value: 'Dry Van', label: 'Dry Van' },
  { value: 'Reefer', label: 'Reefer' },
  { value: 'Flat Bed', label: 'Flat Bed' },
  { value: 'Tanker', label: 'Tanker' },
  { value: 'Dump Truck', label: 'Dump Truck' },
  { value: 'Other', label: 'Other' },
]
