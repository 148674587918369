import MeridiemTime from './meridiemTime'
import DateMonth from './dateMonth'
import DateDate from './dateDate'

/**
 * @name RelativeTime
 *
 * @synopsis
 * ```coffeescript [specscript]
 * RelativeTime(time number) -> string // '36m'
 * ```
 *
 * @description
 * Translate to the relative time (needs to be called again)
 * ```javascript
 * RelativeTime(1617813982259) // Just Now
 * ```
 *
 * Possible Values
 *  * Just now // within 1m
 *  * `/\d{1,59} mins/`
 *  * `/\d{1} hr/`
 *  * `/\d{2,12} hrs/`
 *  * `/Yesterday at \d\d?:\d\d?/ (AM|PM)`
 *  * `/March 30 at \d\d?:\d\d?/ (AM|PM)`
 */

const RelativeTime = function (originalTime) {
  const differenceMillis = Date.now() - originalTime
  const differenceMins = Math.floor(differenceMillis / 1000 / 60)
  if (differenceMins < 1) {
    return 'Just now'
  }
  if (differenceMins < 60) {
    return `${differenceMins} mins ago`
  }
  if (differenceMins < 90) {
    return '1 hr ago'
  }
  const differenceHours = Math.floor(differenceMins / 60)
  if (differenceHours < 12) {
    return `${differenceHours} hrs ago`
  }

  const originalDate = new Date(originalTime)
  if (differenceHours <= 24) {
    const hours = [
      String(originalDate.getHours()).padStart(2, '0'),
      String(originalDate.getMinutes()).padStart(2, '0'),
    ].join('')
    return `Yesterday at ${MeridiemTime(hours)}`
  }
  return `${DateMonth(originalDate)} ${DateDate(
    originalDate
  )} at ${MeridiemTime(
    [
      String(originalDate.getHours()).padStart(2, '0'),
      String(originalDate.getMinutes()).padStart(2, '0'),
    ].join('')
  )}`
}

export default RelativeTime
