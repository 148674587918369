/**
 * @name MeridiemTime
 *
 * @synopsis
 * ```coffeescript [specscript]
 * MeridiemTime(
 *   twentyFourHoursTime string, // '1330'
 * ) -> meridiemTime string // '1:30 PM'
 * ```
 */
const MeridiemTime = function (twentyFourHoursTime) {
  const digits = twentyFourHoursTime.replace(/\D/g, '')
  const hours = digits.slice(0, 2)
  const minutes = digits.slice(2, 4)
  if (Number(hours) == 0) {
    return `12:${minutes} AM`
  } else if (Number(hours) < 12) {
    return `${Number(hours)}:${minutes} AM`
  } else if (Number(hours) == 12) {
    return `12:${minutes} PM`
  } else if (Number(hours) < 24) {
    return `${Number(hours) - 12}:${minutes} PM`
  }
  throw new RangeError('valid range: [0000, 2359]')
}

export default MeridiemTime
