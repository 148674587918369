import React, { useState, useEffect } from 'react'
import firebase from '../../firebase/firebaseConfig'
import TextInputContainer from '../../components/TextInputContainer/TextInputContainer'
import truckDeskLogo from '../../assets/truckdesk_transparent.png'
import Button from '../../components/Buttons/Button'
import addLocalStorage from '../../utils/addLocalStorage'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import './Register.css'

function Register() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [truckingCompanyName, setTruckingCompanyName] = useState('')
  const [accountType, setAccountType] = useState('')
  const [error, seterror] = useState('')
  const [fullName, setfullName] = useState('')
  let location = window.location.pathname

  const history = useHistory()

  useEffect(() => {
    if (location.includes('app')) {
      setAccountType('app')
    } else if (location.includes('portal')) {
      setAccountType('portal')
    }
  }, [])

  const registerWithEmailPassword = () => {
    seterror('')
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        // Signed in
        firebase.firestore().collection('users').doc(email).set({
          fullName,
          truckingCompanyName,
          email,
          emailVerified: false,
          accountType,
          dateRegistered: new Date(),
          hasSeenModal: false,
        })
        console.log(res)
        sendToFirestore()
        addLocalStorage('userEmail', email)
        if (location.includes('app')) {
          history.push('/app')
        } else if (location.includes('portal')) {
          history.push('/portal')
        }
        // ...
      })
      .catch((error) => {
        var errorMessage = error.message

        console.log(errorMessage)
        seterror(errorMessage)
        // ..
      })
  }

  const imageURL =
    'https://truckdesk-images.s3.us-east-2.amazonaws.com/welcome/WelcomeToTruckdesk.png'

  const sendToFirestore = () => {
    const ref = firebase
      .firestore()
      .collection('users')
      .doc(email)
      .collection('documents')
    ref.add({
      documentType: 'Photo',
      documentViewed: false,
      loadNumber: 0,
      bolNumber: 0,
      notes: 'Welcome to Truckdesk!',
      userEmail: 'dispatch@truckdesk.us',
      imageArr: [imageURL],
      timestamp: Date.now(),
    })
    console.log('added new image')
  }

  const sendWelcomeMessage = () => {
    axios({
      method: 'POST',
      url:
        'https://us-central1-truckdesk-prototype.cloudfunctions.net/shareDocWithUsers',
      data: {
        email: email,
      },
    })
      .then(function (response) {
        console.log(`Your message has been sent successfully to Truckdesk!`)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  return (
    <>
      <body
        style={
          location.includes('app')
            ? { height: '100vh' }
            : null
        }
      >
        <div className='app-register-container'>
          <div>
            <img
              alt='truckdesk logo'
              className='truckdesk-logo'
              src={truckDeskLogo}
            />

            <div className='font-bold mb-2 text-2xl'>Sign Up</div>
            {location.includes('app') ? (
              <TextInputContainer
                id={'register-input-email'}
                label='Full Name'
                placeholder='Enter your Full Name'
                class='w-full py-6'
                value={fullName}
                onChange={(e) => setfullName(e.target.value)}
              />
            ) : null}
            <TextInputContainer
              id={'register-input-email'}
              label='Company'
              placeholder='Company'
              class='w-full py-6'
              value={truckingCompanyName}
              onChange={(e) => setTruckingCompanyName(e.target.value)}
            />
            <TextInputContainer
              id={'register-input-email'}
              label='Email'
              placeholder='Email Address'
              class='w-full py-6'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextInputContainer
              id={'register-input-password'}
              label='Password'
              placeholder='Password'
              class='w-full py-6'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={'password'}
            />
          </div>
          <div className='register-button'>
            <Button
              style={{ width: '100%' }}
              className='bg-brand mb-1 py-2 mt-2'
              title='Sign Up'
              fx={registerWithEmailPassword}
            />
          </div>
          <p style={{ color: 'red', marginTop: '1rem' }}>
            {error ? error : null}
          </p>
          <p style={{ color: 'grey', marginTop: '1rem', textAlign: 'center' }}>
            {location.includes('app') ? (
              <>
                <Link
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                    fontWeight: 600,
                  }}
                  to='/login/app'
                >
                  Already have a driver account? <span className='text-brand'>Login here</span>
                </Link>
                <Link
                  style={{
                    textDecoration: 'none',
                    color: 'grey',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    display: 'block',
                  }}
                  to='/register/portal'
                >
                  For portal registration <span className='text-red-500 font-bold'>Click Here</span>
                </Link>
              </>
            ) : (
              <>
                <Link
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                    fontWeight: 600,
                  }}
                  to='/login/portal'
                >
                  Already have a portal account? Login here
                </Link>
                <Link
                  style={{
                    textDecoration: 'none',
                    color: 'grey',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    display: 'block',
                  }}
                  to='/register/app'
                >
                  For driver registration <span className='text-red-500 font-bold'>Click Here</span>
                </Link>
              </>
            )}
          </p>
        </div>
      </body>
    </>
  )
}

export default Register
