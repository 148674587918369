import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../components/Buttons/Button'
import * as FaIcons from 'react-icons/fa'
import firebase from '../../firebase/firebaseConfig'
import UploadImage from '../../components/UploadImage/UploadImage'
import getFromLocalStorage from '../../utils/getFromLocalStorage'
import './DriverAppUpload.css'
import Navbar from '../../components/Navbar/Navbar'
import deleteFromLocalStorage from '../../utils/deleteFromLocalStorage'
import axios from 'axios'
import { withAlert } from "react-alert";

// array of images: http://5.9.10.113/65758183/upload-multiple-images-and-save-to-array-in-react-js

// upload images to s3 using signed URL: https://www.youtube.com/watch?v=yGYeYJpRWPM&t=19s&ab_channel=SamMeech-Ward

const DriverAppUpload = ({alert}) => {
  const [notes, setNotes] = useState('')
  const [documentType, setDocumentType] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [pictures, setPictures] = useState([])
  const [file, setFile] = useState('')
  const [loadNumber, setloadNumber] = useState('')
  const [bolNumber, setbolNumber] = useState('')
  const [companyName, setcompanyName] = useState('')

  const history = useHistory()

  useEffect(() => {
    let email = getFromLocalStorage('userEmail')
    if(email){
      firebase
          .firestore()
          .collection('users')
          .doc(email)
          .onSnapshot(function (doc) {
            setcompanyName(doc.data().truckingCompanyName)
          })
    }
  }, [])

  useEffect(() => {
    let docType = getFromLocalStorage('documentType')
    setDocumentType(docType)

    let contactEmailValue = getFromLocalStorage('contactEmail')
    setContactEmail(contactEmailValue)

    let userEmailValue = getFromLocalStorage('userEmail')
    setUserEmail(userEmailValue)

    let loadNumberValue = getFromLocalStorage('loadNumber')
    setloadNumber(loadNumberValue)

    let bolNumberValue = getFromLocalStorage('bolNumber')
    setbolNumber(bolNumberValue)
  }, [])

  const handleImageUpload = (e) => {
    ;[...e.target.files].forEach((file) => {
      console.log('file >>> ', file)

      setPictures([
        ...pictures,
        {
          data: file,
          url: URL.createObjectURL(file),
        },
      ])
      setFile(file)
    })
  }

  console.log(file)
  console.log('pictures >> ', pictures)
  console.log('pictures length:', pictures.length)

  const submit = () => {
    console.log('test submit')
    if (!file) {
      alert.error('Please select an image to upload.')
    } else {
      console.log('trying to call s3 function')
      sendToS3().then((imageArray) => sendToFirestore(imageArray))
    }
  }

  const sendNotfication = () => {
    let email_data = { doc_type : documentType,
      load_no: loadNumber,
      bol: bolNumber,
      no_pages: pictures.length,

    }
    axios({
      method: 'POST',
      url:
        'https://us-central1-truckdesk-prototype.cloudfunctions.net/sendDocNotification',
      data: {
        toEmail: contactEmail,
        fromEmail: userEmail,
        docType: documentType,
      },
    })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const sendToS3 = async () => {
    let imageArray = []
    console.log('s3 function called')
    for (var i = 0; i < pictures.length; i++) {
      let url = await axios({
        method: 'POST',
        url:
          'https://us-central1-truckdesk-prototype.cloudfunctions.net/sendImageToS3',
        data: {
          documentName: `${documentType}_${loadNumber}_${companyName}`,
        },
      })
        .then(function (response) {
          console.log(response)
          return response.data
        })
        .catch(function (error) {
          console.log(error)
        })

      //Do something

      await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        body: pictures[i].data,
      })
      let imageUrl = url.split('?')[0]
      imageArray.push(imageUrl)
    }
    console.log(imageArray)
    return imageArray
  }

  const sendToFirestore = (imageArray) => {
    const ref = firebase
      .firestore()
      .collection('users')
      .doc(contactEmail)
      .collection('documents')
    ref.add({
      documentType,
      documentViewed: false,
      loadNumber,
      bolNumber,
      notes,
      userEmail,
      imageArr: imageArray,
      timestamp: Date.now(),
    })
    console.log('added to documents collection')
    sendNotfication()
    alert.show(`Document sent to ${contactEmail}.`)
    deleteFromLocalStorage('documentType')
    deleteFromLocalStorage('contactEmail')
    history.push('/app')
  }

  return (
    <>
      <Navbar />
      <body style={{ backgroundColor: '#ddd', height: '100vh' }}>
        <div className='driver-app-upload-container'>
          <div className='flex flex-row justify-between justify-center my-2'>
            <div className='flex flex-col justify-center'onClick={() => history.push('/app')}><FaIcons.FaArrowLeft
                className='text-white'
            /></div>
            <div><button className='bg-white rounded shadow text-brand py-1 px-6 font-bold' onClick={() => submit()}>Send</button></div>
          </div>
          <div></div>
          <div className='text-white mt-4 mb-4'>Add Pages</div>
          <div className='bg-white p-4 rounded-xl shadow flex flex-wrap grid grid-cols-3 gap-2'>

          <div className='border-dashed border-brand border-2 bg-gray-100 p-6 rounded-xl h-36 flex flex-col justify-center'>
            <UploadImage
              style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem' }}
              title='Add More Pages'
              onChange={handleImageUpload}
            />
          </div>

            {pictures?.map((pic) => (
                <div className='border rounded-xl h-36 relative'>
                <div className='absolute mt-2 mr-2 right-1'><FaIcons.FaTrash
                    className='text-brand'
                /></div>
                <img className='uploaded-image-preview' src={pic.url} />
                </div>
            ))}

          </div>
          {/* {pictures.length > 1 ? (
          <BackNextButton onClick={goToNextPage} title='Next' />
        ) : null} */}
          <div className='text-gray-500 my-4 font-bold'>Notes</div>
          <div className='bg-white px-4 py-4 rounded-xl shadow relative'>
          <textarea
            className='textarea-add-message border '
            maxlength='140'
            style={{ width: '100%'}}
            placeholder='Add Notes (Optional)'
            id='message'
            name='message'
            rows='4'
            cols='30'
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          ></textarea>
          <span className='absolute bottom-0 right-4 text-gray-400 font-xs'>{ notes.length}/140</span>
          </div>
          {/* <button onClick={sendToS3}>Send to S3</button> */}

        </div>
      </body>
    </>
  )
}

export default withAlert()(DriverAppUpload)

