import React from 'react'
import ProtectedRoute from './ProtectedRoute'
import AuthRoute from './AuthRoute'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Register from './containers/AppRegister/Register'
import Login from './containers/AppLogin/Login'
import ForgotPassword from './containers/ForgotPassword/ForgotPassword'
import DriverApp from './containers/DriverApp/DriverApp'
import DriverAppUpload from './containers/DriverApp/DriverAppUpload'
import PageNotFound from './containers/PageNotFound/PageNotFound'
import ContactUs from './containers/ContactUs/ContactUs'
import ProfileApp from './containers/Profile/ProfileApp'
import DocHistory from './containers/DocHistory/DocHistory'


function App() {
  return (
    <>
      <Router>
        <Switch>
          <AuthRoute exact path='/register/app' component={Register} />
          <AuthRoute exact path='/register/portal' component={Register} />
          <AuthRoute exact path='/login/app' component={Login} />
          <AuthRoute exact path='/login/portal' component={Login} />
          <AuthRoute exact path='/forgot-password' component={ForgotPassword} />

          <ProtectedRoute exact path='/' component={DriverApp} />
          <ProtectedRoute exact path='/doc-history' component={DocHistory} />
          <ProtectedRoute
            exact
            path='/app/upload'
            component={DriverAppUpload}
          />
          <ProtectedRoute exact path='/app/contact-us' component={ContactUs} />
          <ProtectedRoute exact path='/app/profile' component={ProfileApp} />

          <ProtectedRoute
            exact
            path='/portal/contact-us'
            component={ContactUs}
          />
          <Route path='*' component={DriverApp} />
        </Switch>
      </Router>
    </>
  )
}

export default App
