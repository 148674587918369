import React from 'react'
import './DocButton.css'

const DocButton = (props) => {
  return (
    <button
      onClick={props.onClick}
      className={
        props.selected === true
          ? 'select-doc-button-selected'
          : 'select-doc-button'
      }
    >
      {props.title}
    </button>
  )
}

export default DocButton
